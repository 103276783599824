/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '../SvgIcon';

export default (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon {...props} viewBox="0 0 32 32" iconName="IconIcSourceMetaDesk">
    <circle cx="16" cy="16" r="16" fill="white"/><path d="M20.8753 25.9961H20.8785C23.707 25.9961 26 23.8715 26 21.2508L26 10.7343C26 8.11359 23.707 5.98903 20.8785 5.98903H20.8753C18.0467 5.98903 15.7538 8.11359 15.7538 10.7343L15.7538 21.2508C15.7538 23.8715 18.0467 25.9961 20.8753 25.9961Z" fill="#0096FF"/><path d="M20.8789 25.9957V14.8613H15.7574V25.9957H20.8789Z" fill="#0096FF"/><path d="M10.2307 14.9727H10.2274C7.88703 14.9727 5.98975 16.7306 5.98975 18.8991V22.0696C5.98975 24.2381 7.88703 25.996 10.2274 25.996H10.2307C12.5711 25.996 14.4684 24.2381 14.4684 22.0696V18.8991C14.4684 16.7306 12.5711 14.9727 10.2307 14.9727Z" fill="#FF3296"/><path d="M14.4648 25.9976V21.0273H9.84513V25.9976H14.4648Z" fill="#FF3296"/><path d="M10.2274 13.845C12.5679 13.845 14.4651 12.0871 14.4651 9.91863C14.4651 7.75013 12.5679 5.99219 10.2274 5.99219C7.88703 5.99219 5.98975 7.75013 5.98975 9.91863C5.98975 12.0871 7.88703 13.845 10.2274 13.845Z" fill="#FFC814"/>
  </SvgIcon>
);
