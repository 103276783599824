/* eslint-disable max-len */
import React from 'react';
import SvgIcon from '../SvgIcon';

export default (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <SvgIcon {...props} viewBox="0 0 12 11" iconName="IconIcRequired">
    <circle cx="5.96733" cy="5.90611" r="4.56987" fill="white" stroke="#FF3296"/><circle cx="5.96753" cy="5.90604" r="3.19214" fill="#FF3296"/>
  </SvgIcon>
);
